var Init = {

  landscape() {
    // var landscapeOrientation = window.matchMedia("(orientation: landscape)");

    // if(landscapeOrientation.matches) {
    // 	$('meta[name="viewport"]').attr('content', '');
    // }

    // landscapeOrientation.addListener(function(orientation) {
    // 	if(orientation.matches) {
    // 		$('meta[name="viewport"]').attr('content', ''); // Изменено на портретный режим
    // 	}
    // 	else {
    // 		$('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1'); // Изменено на горизонтальный режим
    // 	}
    // });
  },
  openPopup(popupID) {
    Init.closeAllPopups();
    if (popupID) {
      let popup = $(popupID);
      let win = popup.find('.popup__window');
      popup.fadeIn(500);
      if ($(window).width() < $(window).height()) {
        $('html, body').css('overflow', 'hidden');
      }
      $('html').addClass('popup-opened');
      win.fadeIn(500);
      popup.trigger('popupopened');
      $(window).trigger('popupopened');

      if (popup.attr('id') == 'crop-popup') {
        $(window).trigger('crop-popupopened');
      }
    } else {
      console.error('Which popup?');
    }
  },
  closePopup(popupID) {
    if (popupID) {
      let popup = $(popupID);
      let win = popup.find('.popup__window');
      win.fadeOut(500);
      popup.fadeOut(500);
      $('html').removeClass('popup-opened');
      if ($(window).width() < $(window).height()) {
        $('html, body').css('overflow', 'visible');
      }
      $(window).trigger('popupclosed');
    } else {
      console.error('Which popup?');
    }
  },
  closeAllPopups() {
    let popup = $('.popup');
    let win = popup.find('.popup__window');
    win.fadeOut(200);
    popup.fadeOut(200);
    $('html').removeClass('popup-opened');
    if ($(window).width() < $(window).height()) {
      $('html, body').css('overflow', 'visible');
    }
    popup.trigger('popupclosed');
    $(window).trigger('popupclosed');
  },
  popup() {
    $(document).on('click', '.btn-close-popup', function(){
      let popup = !!$(this).attr('data-target') ? $($(this).attr('data-target')) : $(this).closest('.popup').attr('id');
      Init.closePopup(`#${popup}`);
    });

    $(document).on('click', '.popup', function() {
      if (!$(this).hasClass('unclosed')) {
        Init.closePopup(`#${$(this).attr('id')}`);
      }
    });

    $(document).on('click', '.popup__window, .popup .mCSB_scrollTools', function(e) {
      e.stopPropagation();
    });

    $(document).on('click', '.btn-popup', function(e) {
      e.preventDefault();
      let target = $(this).attr('data-target') === 'self' ? $(this).parent() : $($(this).attr('data-target'));
      Init.openPopup(target);
    });
  },
  feedbackPopup() {
    $('.js-feedback-select').on('change', function() {
      $('.js-feedback-select').parents('.field__label').removeClass('has-error');
      $('.js-feedback-select').parents('.form__field').removeClass('has-error');
    });
    $('.js-feedback-start').on('click', function(e) {
      e.preventDefault();
      var type = $('.js-feedback-select').val();
      var field = $('.js-feedback-select').parents('.field__label');
      var fieldWrap = $('.js-feedback-select').parents('.form__field');
      field.removeClass('has-error');
      fieldWrap.removeClass('has-error');
      if (!type) {
        field.addClass('has-error');
        fieldWrap.addClass('has-error');
        return;
      }
      if (type == 'other' || type == 'tech'){
        changeStep($('.js-feedback-step[data-type="tech"]'));
      } else {
        changeStep($('.js-feedback-step[data-type="quality"]'));
      }
    });
    $('.js-feedback-start-index').on('click', function(e) {
      e.preventDefault();
      var type = $('.js-feedback-select-index').val();
      var field = $('.js-feedback-select-index').parents('.field__label');
      var fieldWrap = $('.js-feedback-select-index').parents('.form__field');
      field.removeClass('has-error');
      fieldWrap.removeClass('has-error');
      if (!type) {
        field.addClass('has-error');
        fieldWrap.addClass('has-error');
        return;
      }
      console.log('df');
      Init.openPopup('#feedback_popup');
      if (type == 'other' || type == 'tech'){
        changeStep($('.js-feedback-step[data-type="tech"]'));
      } else {
        changeStep($('.js-feedback-step[data-type="quality"]'));
      }
    });

    $('.js-feedback-next').on('click', function(e) {
      e.preventDefault();
      var nextStep = $(this).parents('.js-feedback-step').next();
      var notValid = validateForm($(this).parents('.js-feedback-step')[0]);

      if (nextStep.length && !notValid){
        changeStep(nextStep);
      }
    });

    $('.js-feedback-prev').on('click', function(e) {
      e.preventDefault();
      var prevStep = $(this).parents('.js-feedback-step').prev();
      if (prevStep.length){
        changeStep(prevStep);
      } else {
        changeStep($('.js-feedback-step[data-type="start"]'));
      }
    });

    $('.js-feedback-send').on('click', function(e) {
      e.preventDefault();
      var notValid = validateForm($(this).parents('.js-feedback-step')[0]);
      if (!notValid){
        sendFeedback();
      }
    });

    $('.js-feedback-form').find('input[type="text"], input[type="email"], textarea').on('blur', function(e) {
      var valid = validateField($(this)[0]);

      if ($(this).val()) {
        $(this).parent().addClass('has-value');
      } else {
        $(this).parent().removeClass('has-value');
      }
    });

    $('.js-feedback-form').find('input[type="text"], input[type="email"], textarea').on('focus', function(e) {
      $(this).parent().removeClass('has-error');
      $(this).parents('.form__field').removeClass('has-error');
    });

    $('.js-feedback-form').find('select[required="required"]').on('change', function(e) {
      $(this).parent().removeClass('has-error');
      $(this).parents('.form__field').removeClass('has-error');
    });

    function changeStep(el) {
      $('.js-feedback-step').removeClass('active');
      el.addClass('active');
    }

    function validateField(field) {
      var isValid = field.validity.valid;
      var fieldContainer = $(field).parent();
      var fieldwrap = $(field).parents('.form__field');
      if (isValid) {
        fieldContainer.removeClass('has-error');
        fieldwrap.removeClass('has-error');
      } else {
        fieldContainer.addClass('has-error');
        fieldwrap.addClass('has-error');
      }
      return isValid;
    }

    function isHidden(el) {
      var style = window.getComputedStyle(el);
      return ((style.display === 'none') || (style.visibility === 'hidden'));
    }

    function validateForm(elForm) {
      var errors = 0;
      elForm.querySelectorAll('input, textarea, select').forEach(function(item) {
        if (!isHidden(item)) {
          var isValidField = validateField(item);
          if(!isValidField) {
            errors += 1;
          }

          if ($(item).val()) {
            $(item).parent().addClass('has-value');
          } else {
            $(item).parent().removeClass('has-value');
          }
        }
      });
      return errors;
    }

    function errorsCheck(obj, form) {
      form.find('.field__error').html('');
      $.each(obj, function (i, el) {
        var errInput = form.find("input[name='"+i+"']");
        errInput.parents('.form__field').find('.field__error').html(el[0]);
        errInput.parents('.form__field').addClass('has-error');
        errInput.parent().addClass('has-error');
      });
    }

    function sendFeedback() {
      var data = new FormData();
      var currentType = $('.js-feedback-select').find('option:selected').attr('data-target');
      var form = $('.js-feedback-form').find('.js-feedback-step[data-type='+currentType+']').parent();

      data.append('type', $('.js-feedback-select').val());
      data.append('name', form.find('[name="name"]').val());
      if (form.find('[name="surname"]').val()) data.append('surname', form.find('[name="surname"]').val());
      if (form.find('[name="patronymic"]').val()) data.append('patronymic', form.find('[name="patronymic"]').val());
      data.append('phone', form.find('[name="phone"]').val());
      data.append('email', form.find('[name="email"]').val());
      if (form.find('[name="address_locality"]').val()) data.append('address_locality', form.find('[name="address_locality"]').val());
      if (form.find('[name="address_street"]').val()) data.append('address_street', form.find('[name="address_street"]').val());
      if (form.find('[name="address_number"]').val()) data.append('address_number', form.find('[name="address_number"]').val());
      if (form.find('[name="address_index"]').val()) data.append('address_index', form.find('[name="address_index"]').val())
      if (form.find('[name="email_answer"]:checked').length) data.append('email_answer', 'yes');
      if (form.find('[name="agreement"]:checked').length) data.append('agreement', 'yes');
      if (form.find('[name="contacted_me"]:checked').length) data.append('contacted_me', 'yes');
      if (form.find('[name="comment"]').val()) data.append('comment', form.find('[name="comment"]').val());

      if ($('.js-feedback-select').find('option:selected').attr('data-target') == 'tech') {
        if ($("#feedback_file").val()) {
          data.append('photo', $("#feedback_file").get(0).files[0]);
        }
      } else {
        if (form.find('[name="store_name"]').val()) data.append('store_name', form.find('[name="store_name"]').val());
        if (form.find('[name="store_address"]').val()) data.append('store_address', form.find('[name="store_address"]').val());
        if (form.find('[name="product_name"]').val()) data.append('product_name', form.find('[name="product_name"]').val());
        if (form.find('[name="product_maker"]').val()) data.append('product_maker', form.find('[name="product_maker"]').val());
        if (form.find('[name="product_weight"]').val()) data.append('product_weight', form.find('[name="product_weight"]').val());
        if (form.find('[name="product_marking"]').val()) data.append('product_marking', form.find('[name="product_marking"]').val());
        if ($('.js-select-category').val()) data.append('product_category', $('.js-select-category').val());

        if ($("#feedback_file_mark").val()){
          data.append('photo_marking', $("#feedback_file_mark").get(0).files[0]);
        }
        if ($("#feedback_file_def").val()){
          data.append('photo_defect', $("#feedback_file_def").get(0).files[0]);
        }
        if ($("#feedback_file_box").val()){
          data.append('photo_front', $("#feedback_file_box").get(0).files[0]);
        }
        if ($("#feedback_file_box2").val()){
          data.append('photo_back', $("#feedback_file_box2").get(0).files[0]);
        }
      }
      setTimeout(function(){sendRequest()},10);

      function sendRequest() {
        var selfSubmit = $('.js-feedback-send');
        var request = $.ajax({
          url: 'https://tchibo.emlsdr.ru/api/feedback',
          type: 'POST',
          dataType: 'json',
          contentType: false,
          cache: false,
          processData: false,
          timeout: 0,
          mimeType: 'multipart/form-data',
          data: data,
          beforeSend: function () {
            selfSubmit.attr('disabled', 'disabled');
          }
        });
        request.done(function (response, textStatus, jqXHR) {
          if (!response.errors) {
            Init.openPopup('#feedback_success-popup');
            $('.js-feedback-form').find('input[type="text"], input[type="email"]').val('');
            $('.js-feedback-form').find('input[type="text"], input[type="email"]').parent().removeClass('has-value');
            $('.js-feedback-step').removeClass('active');
            $('.js-feedback-step[data-type="start"]').addClass('active');
          } else {
            errorsCheck(response.errors, form);
          }
          selfSubmit.attr('disabled', false);
        });
        request.fail(function (jqXHR, textStatus) {
          console.log("Request failed: " + textStatus);
          selfSubmit.attr('disabled', false);
        });
      }
    }
  },
  select() {
    if ($('.js-select').length) {
      $('.js-select').select2({
        width: 'style',
        minimumResultsForSearch: -1
      });
    }
  },
  accordion() {
    $(document).on('click', '.js-accordion-panel', function(){
      $(this).closest('.js-accordion').siblings('.js-accordion').removeClass('active');
      $(this).closest('.js-accordion').siblings('.js-accordion').find('.js-accordion-content').slideUp(300);
      $(this).closest('.js-accordion').toggleClass('active');
      $(this).siblings('.js-accordion-content').slideToggle(300);
    });
  },
  fileUpload() {
    function validateImage(context, image) {
    	var validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'];
    	if (validTypes.indexOf( image.type ) === -1) {
        context.parent().addClass('has-error');
    		return false;
    	}
    	var maxSizeInBytes = 50e6; // 50MB
    	if (image.size > maxSizeInBytes) {
        context.parent().addClass('has-error');
    		return false;
    	}
    	return true;
    }

    function previewImage(context, image) {
    	var reader = new FileReader();
      var field = context.parents('.field');
      field.find('.field__filezone').hide();
    	reader.onload = function(e) {
        field.find('.js-file-preview').css('background-image', 'url("'+e.target.result+'")');
    	}
    	reader.readAsDataURL(image);

      var fullPath = context.val();
      var pathEl = context.parents('.field').find('.js-file-path');
      if (fullPath) {
        var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        var filename = fullPath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
          filename = filename.substring(1);
        }
        context.parents('.field').addClass('_show-path');
        pathEl.html(filename);
      }
    }

    $(document).on('change', '.field--file input[type="file"]', function () {
      let files = $(this).get(0).files;

      $(this).parent().removeClass('has-error');
      for (var i = 0, len = files.length; i < len; i++) {
        if (validateImage($(this), files[i])) {
          previewImage($(this), files[i]);
        } else {
          $(this).val('');
        }
      }
    });

    $(document).on('click', '.js-file-path-remove', function (){
      $(this).parents('.field').removeClass('_show-path');
      $(this).parents('.field').find('input[type="file"]').val('');
      $(this).parents('.field').find('.field__filezone').show();
    });
    Inputmask({"mask": "+7 (999) 999-99-99", "clearMaskOnLostFocus": true}).mask('input[name="phone"]');
  },
  header() {
    $('.js-button-back').on('click', function(e){
        e.preventDefault();
        window.history.back();
    });
    $('.js-menu-close').click(function () {
      $('.mobile-menu').fadeOut();
      $('.js-menu-opener').removeClass('opened').addClass('closed');
    });
    $('.js-menu-opener').click(function () {
      if ($(this).hasClass('closed')) {
        $(this).removeClass('closed');

        // $('body').addClass('no-scroll');

        // $('.headerWrapper .m-metanav').fadeOut();

        $('.mobile-menu').fadeIn();
        // $(this).hide();
        $('.c-hamburger-line:nth-child(2)').hide();
        $('.c-hamburger-line:nth-child(1)').css({'transform': 'rotate(45deg) translate(-5px, -5px)', 'margin-bottom': '-2px', 'margin-left': '5px'});
        $('.c-hamburger-line:nth-child(3)').css({'transform': 'rotate(-45deg)', 'margin-left': '5px'});
        // $(this).show();

        $(this).addClass('opened');

      } else {
        $(this).removeClass('opened');
        $('.mobile-menu').fadeOut();
        // $('.headerWrapper .m-metanav').fadeIn();
        $('.c-hamburger-line').attr('style', '');

        $(this).addClass('closed');

        $('body').removeClass('no-scroll');
      }
    });
    $('.mobile-menu__bg').click(function () {
      $('.c-hamburger').click();
    });
  },
  switchers() {
    $('.m-about-qs-years-switch').click(function () {
      var contentId = $(this).attr('data-content');
      var switchClosed = !$(this).hasClass('m-about-qs-years-switch--visible');
      var sliderContainer = $('.AboutSlider .m-tp-swiper-container');
      var sliderItem = $('.AboutSlider .m-about-qs-descriptions-item');

      if (device.mobile() && device.portrait()) {
        sliderContainer = $('.AboutSliderMobile .m-tp-swiper-container');
      }

      if (switchClosed) {
        closeAllSwitchers();


        $(this).addClass('m-about-qs-years-switch--visible');
        $('div[data-id="' + contentId + '"]').removeClass('h-tp-visuallyhidden');


        if ($('.m-about-qs-years-switch').hasClass('m-about-qs-years-switch--visible')) {
          var p = $('.m-about-qs-years-switch--visible');
          var b = $('.js-afterelement-block');
          var position = p.position();
          window.$testb = b;
          window.$testp = p;
          //b.css("opacity", "1");
          b.animate({'left': position.left + 78 + "px"}, 400);
          $(".m-bannerMore-image").addClass('m-about-qs-descriptions-item--anim');
          $(".copytext").addClass('m-about-qs-descriptions-item--anim');
        }
      } else {
        var b = $('.js-afterelement-block');
        $(this).removeClass('m-about-qs-years-switch--visible');
        $('div[data-id="' + contentId + '"]').addClass('h-tp-visuallyhidden');
        //b.css("opacity", "0");
        $('.m-about-qs-years-switch').removeClass('m-about-qs-years-switch--visible');
        $('.m-about-qs-descriptions-item').addClass('h-tp-visuallyhidden');
      }

      function closeAllSwitchers() {
        $('.m-about-qs-years-switch').removeClass('m-about-qs-years-switch--visible');
        $('.m-about-qs-descriptions-item').addClass('h-tp-visuallyhidden');
        var b = $('.js-afterelement-block');
        // b.css("opacity", "0");

        $(".m-bannerMore-image").removeClass('m-about-qs-descriptions-item--anim');
        $(".copytext").removeClass('m-about-qs-descriptions-item--anim');
      }

      //треугольник над слайдером. перемещение при клике.
      $(".m-about-qs-years-switch").on("click", function (e) {
        $(".js-afterelement-block").addClass("js-afterelement-block--anim");
        setTimeout(function () {
          $(".js-afterelement-block").removeClass("js-afterelement-block--anim");
        }, 1000);
      });
    });
  },
  itemModal() {
    $(window).load(function () {
      var locationId = location.hash;
      if (locationId) {
        // $(locationId).css('display', 'flex');
        $('.product-modals').css('display', 'flex');
        $('.product-modals').animate({'opacity': '1'}, 500);
      }

    });

    $('.block-title-price a').on('click', function (e) {
      e.preventDefault();

      var lockId = location.hash;
      if (lockId) {
        $(lockId).css('display', 'flex');
        $('.product-modals').css('display', 'flex');
        $('.product-modals').animate({'opacity': '1'}, 500);
      }
    });

    $('.js-open-modal').click(function (e) {
      e.preventDefault();

      var modalId = $(this).attr('href');
      openItemModal(modalId);

    });

    $('.modal-item__close-btn, .product-modals-bg').click(function () {
      closeItemModal();
    });

    function openItemModal(modalId) {
      $(modalId).css('display', 'flex');
      $('.product-modals').css('display', 'flex');
      $('.product-modals').animate({'opacity': '1'}, 500);
    }

    function closeItemModal() {
      $('.product-modals').animate({'opacity': '0'}, 500);
      window.location.hash = '';
      setTimeout(function () {
        $('.product-modals').hide();
        $('.modal-item').hide();
      }, 500);
    }
  },
  initLink() {
    $('.swiper-button-next').click(function (e) {
      var b = $('.js-afterelement-block');
      // b.css("opacity", "0");
    });
    $('.swiper-button-prev').click(function (e) {
      var b = $('.js-afterelement-block');
      // b.css("opacity", "0");
    });
    if ($('.m-about-qs-switch-2003').hasClass('m-about-qs-switch-2003-custom')) {
      var p = $('.m-about-qs-switch-2003-custom');
      var b = $('.js-afterelement-block');
      var position = p.position();
      // b.css("opacity", "1");
      b.animate({'left': position.left + 78 + "px"}, 800);
    }
    ;
  },
  initSearch() {
    // $('#headerSearchForm').click(function(e) {
    //     e.preventDefault();
    //     $("#block-search-result").show();

    //     if (!$(e.target).closest("#headerSearchForm").length) {
    //         $("#block-search-result").hide();
    //     }
    // });
    // $(document).mouseup(function (e){
    //     var div = $("#headerSearchForm");
    //     if (!div.is(e.target) && div.has(e.target).length === 0) {
    //         $("#block-search-result").hide();
    //     }
    // });
  },
  initlistSearch() {
    var timer;
    var output = $('#block-search-result');
    var textSearch;

    function post_list() {
      $.ajax({
        url: '/search?search=' + textSearch,
        type: "GET",
        dataType: "html",
        success: function (html) {
          output.html('');
          $("#block-search-result").show();
          output.html(html);
        }
      });
    }

    $("#headerSearchForm").on('submit', function (e) {
      e.preventDefault();
    });
    $(".searchText").on('input focusin', function () {
      textSearch = $(this).val();
      if (textSearch.length > 2) {
        clearTimeout(timer);
        timer = setTimeout(post_list, 100);
      }
      if (textSearch.length < 2) {
        $("#block-search-result").hide();
      }
    });
    // $( ".block-search-result" ).on('focusin', function() {
    //     textSearch = $(this).val();
    //     if(textSearch.length > 3) {
    //         clearTimeout(timer);
    //         timer = setTimeout(post_list, 100);
    //     }
    // });


    /* события при вводе данных. инпут вне фокуса */

    // $('.searchText').blur(function(){
    //     var output = $('#list-search-result li');
    //     output.html('');
    //     $("#block-search-result").hide();
    // });

    $(document).on('click', function (e) {
      var $currentEl = $(e.target);
      var lrn = $currentEl.find('#block-search-result');
      if ($currentEl.find('#block-search-result').length != 0) {
        var output = $('#block-search-result');
        output.html('');
        $("#block-search-result").hide();
      }
    });

    document.addEventListener("keydown", function (e) {
      if (13 === e.keyCode) {
        var link = $($('#block-search-result').find('#list-search-result').find('li:first').find('div.block-title-price a')[0]).attr('href');
        if (link !== undefined) {
          window.location = link;
        }
      }
    });

    $(document).on('click', '.block-title-price a', function (e) {
      setTimeout(function () {
        var b = location.hash;
        $(b).css('display', 'flex');
        $('.product-modals').css('display', 'flex');
        $('.product-modals').animate({'opacity': '1'}, 500);
        $("#block-search-result").hide();
      }, 0);
    });
  },
  initSlickSlider() {
    $('.slider').slick({
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 5,
      slidesToScroll: 5,
      slide: 'div:not(js-afterelement-block)',
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    // .slide-perv.slide--deleted
    var itemOne = $('.slide-perv').offset();
    var v = $('.js-afterelement-block');
    v.animate({'left': itemOne.left + 70 + "px"}, 400);

    var allSlide = document.getElementsByClassName('slick-slide');
    for (var i = 0; i < allSlide.length; i += 5) {
      allSlide[i].classList.add("slick-active");
    }


    $('.data-link').click(function () {
      var switchClosed = !$(this).hasClass('data-link--visible');
      var contentId = $(this).attr('data-content');
      var sliderItem = $('.slick-slider-items .m-about-qs-descriptions-item');
      var item = $('.m-about-qs-descriptions-item');


      if (switchClosed) {
        closeAllSwitchers();
        $(this).addClass('data-link--visible');
        $('.m-bannerMore-image').addClass('m-bannerMore-image--visible');
        $('.copytext').addClass('copytext--visible');
        $('div[data-id="' + contentId + '"]').removeClass('h-tp-visuallyhidden');
        $('div[data-id="' + contentId + '"]').addClass('m-about-qs-descriptions-item--img');


        if ($('.data-link').hasClass('data-link--visible')) {
          var p = $(this).parents('.slick-active');
          var b = $('.js-afterelement-block');
          var offset = p.offset();
          window.$testb = b;
          window.$testp = p;
          b.animate({'left': offset.left + 70 + "px"}, 400);
          b.removeClass('js-afterelement-block--hidden');

        }
      } else {
        $(this).removeClass('data-link--visible');
        $('.data-link').removeClass('data-link--visible');
        $('div[data-id="' + contentId + '"]').addClass('h-tp-visuallyhidden');
        $('.m-about-qs-descriptions-item').addClass('h-tp-visuallyhidden');
        $('div[data-id="' + contentId + '"]').addClass('m-about-qs-descriptions-item--img');
        $('.m-bannerMore-image').removeClass('m-bannerMore-image--visible');
        $('.copytext').removeClass('copytext--visible')
        var b = $('.js-afterelement-block');
        b.addClass('js-afterelement-block--hidden');
      }

      function closeAllSwitchers() {
        $('.m-bannerMore-image').addClass('m-bannerMore-image--visible');
        $('.copytext').addClass('copytext--visible')
        $('.data-link').removeClass('data-link--visible');
        $('.m-about-qs-descriptions-item').addClass('h-tp-visuallyhidden');
        var b = $('.js-afterelement-block');
        b.addClass('js-afterelement-block--hidden');
        $('.m-about-qs-descriptions-item').removeClass('m-about-qs-descriptions-item--img');
      }
    });
    $('.slick-arrow').click(function () {
      closeAllSwitchers();
      // var b = $('.js-afterelement-block');
      // console.log(b)
      // b.addClass('js-afterelement-block--hidden');

      function closeAllSwitchers() {
        $('.data-link').removeClass('data-link--visible');
        $('.m-about-qs-descriptions-item').addClass('h-tp-visuallyhidden');
        $('.m-about-qs-descriptions-item').removeClass('m-about-qs-descriptions-item--img');
      }

      var ollSlide = document.getElementsByClassName('slick-slide');
      for (var i = 0; i < ollSlide.length; i += 5) {
        ollSlide[i].classList.add("slick-active");
      }
      var linkOne = $('.slick-current').find('.data-link-icon .data-link').attr('data-content');
      var sctiveTriangle = $('.slick-current').find('.data-link-icon .data-link');
      var activeClass = $('.slick-current').find('.data-link-icon .data-link');

      $(activeClass).addClass('data-link--visible');
      $('div[data-id="' + linkOne + '"]').removeClass('h-tp-visuallyhidden');
      $('div[data-id="' + linkOne + '"]').find('.copytext').addClass('copytext--visible')
      $('div[data-id="' + linkOne + '"]').find('.data-link').removeClass('data-link--visible');
      $('div[data-id="' + linkOne + '"]').find('.m-bannerMore-image').addClass('m-bannerMore-image--visible');

      setTimeout(function () {
        var p = $('.slick-current');
        var b = $('.js-afterelement-block');
        var offset = p.offset();
        b.animate({'left': offset.left + 70 + "px"}, 400);
        b.removeClass('js-afterelement-block--hidden');
      }, 610);
    });
    $(".data-link").on("click", function (e) {
      $(".js-afterelement-block").addClass("js-afterelement-block--anim");
      setTimeout(function () {
        $(".js-afterelement-block").removeClass("js-afterelement-block--anim");
      }, 1000);
    });
  },

  initProductSlider() {
    $(window).on('load resize orientationchange', function() {
     
      if ($(window).width() > 768) {
        if ($('.ProductSlider__slides').hasClass('slick-initialized')) {
          $('.ProductSlider__slides').slick('unslick');
        }
      } else {
        if (!$('.ProductSlider__slides').hasClass('slick-initialized')) {
          $('.ProductSlider__slides').slick({
            autoplay: true,
            autoplaySpeed: 4000,
            dots: false,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            prevArrow: '.ProductSlider__button_prev',
            nextArrow: '.ProductSlider__button_next',
          })
        }
      }
    });
  }
}

$(function () {
  Init.landscape();
  Init.popup();
  Init.feedbackPopup();
  Init.select();
  Init.accordion();
  Init.fileUpload();
  Init.header();
  Init.switchers();
  Init.itemModal();
  Init.initSearch();
  Init.initLink();
  Init.initlistSearch();
  Init.initSlickSlider();
  Init.initProductSlider();
});

$('.js-copyright span').text(new Date().getFullYear())

$('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.slider-nav'
});
$('.slider-nav').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  dots: false,
  focusOnSelect: true
}); 
$('.slider-roasting').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  dots: true,
}); 

var buyOnline = document.querySelector('.l-header-main .m-metanav-link--buyonline');
var buyPopup = document.querySelector('.l-header-main .m-metanav__popup');
var popupState = void 0;
buyOnline.addEventListener('click', function () {
  popupState = buyPopup.style.display;
  buyPopup.style.display = popupState === 'flex' ? 'none' : 'flex';
  popupState = buyPopup.style.display;
});
var buyOnlineFooter = document.querySelector('.l-tp-footer .m-metanav-link--buyonline');
var buyPopupFooter = document.querySelector('.l-tp-footer .m-metanav__popup');
var popupStateFooter = void 0;
buyOnlineFooter.addEventListener('click', function () {
  popupStateFooter = buyPopupFooter.style.display;
  buyPopupFooter.style.display = popupStateFooter === 'flex' ? 'none' : 'flex';
  popupStateFooter = buyPopupFooter.style.display;
});
document.addEventListener('click', function (event) {
  if (popupState === 'flex' && event.target !== buyPopup && event.target !== buyOnline) buyPopup.style.display = 'none';
  if (popupStateFooter === 'flex' && event.target !== buyPopupFooter && event.target !== buyOnlineFooter) buyPopupFooter.style.display = 'none';
});
$('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      focusOnSelect: true
    });
